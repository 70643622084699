import Helmet from "react-helmet"
import React from "react"
import AMOthankyou from "src/components/modules/amotyp"




export default function TvThankYouAmo() {
  return(
    <section>
      <Helmet><title>Thank you for installing Freshy Search - freshysearch.com</title></Helmet>
      <AMOthankyou></AMOthankyou>

    </section>
  )
}
